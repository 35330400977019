


















































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import api from "@/api";
import uploadNew from "@/components/uploadNew.vue";
import {clientLink, showTagRE} from '@/utils/common'

@Component({
    name: "activityAdd",
    components: {
        uploadNew: uploadNew
    }
})

export default class extends Vue {
    model: any = {
        OSSAccessKeyId: "",
        signature: "",
        policy: "",
        key: "",
        callback: "",
        file: "",
        success_action_status: ''
    };
    fileList: any = {
        image_url: '',
        image_name: ''
    }
    fileList2: any = {
        image_url: '',
        image_name: ''
    }

    getData1(item: any) {
        this.form.popupImg = item
    }
    getData2(item: any) {
        this.form.bannerImg = item
    }

    influenceRange: any = []
    tradingHour: any = []
    form: any = {
        validityStartTime: '',
        validityEndTime: '',

    }

    // 标签
    tagVal = ''
    tags: any = []
    tagsC: any = []
    newTags: any = []
    delTags: any = []

    querySearch(queryString: any, cb: any) {
        var restaurants = this.labelList;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
    }
    createFilter(queryString: any) {
        return (restaurant: any) => {
            return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
    }
    handleClose(tag: any) {
        this.delTags.push(tag)
        this.tags.splice(this.tags.indexOf(tag), 1);

    }
    handleClose2(tag: any) {
        this.newTags.splice(this.newTags.indexOf(tag), 1);
    }
    aTag() {
        if(!this.tagVal) {
            this.$message.warning('请输入内容')
            return
        }
        let all = [...this.tags, ...this.newTags]
        let a = all.indexOf(this.tagVal)
        if(a>-1) {
            this.$message.warning('存在相同标签')
            return;
        }
        this.newTags.push(this.tagVal)
        this.tagVal = ''
    }
    labelList = []
    labelTable() {
        api.post('J27605').then(res => {
            this.labelList = res.list
        })
    }

    sureLoading = false
    sure() {
        if(!this.form.activityName){
            this.$message.warning('请填写活动名称')
            return
        }
        if(this.tradingHour.length == 0){
            this.$message.warning('请填写活动有效期')
            return
        }
        if(!this.form.influenceRange){
            this.$message.warning('请选择活动渠道')
            return
        }
        if(!this.form.popup){
            this.$message.warning('请选择是否弹窗')
            return
        }
        if(!this.form.activityUrl){
            this.$message.warning('请填写活动链接')
            return
        }
        if(!this.form.bannerStatus){
            this.$message.warning('请选择是否首页banner')
            return
        }
        if(this.form.weight === ''){
            this.$message.warning('请填写弹窗排序')
            return
        }
        if(this.form.weightBanner===""){
            this.$message.warning('请填写首页排序')
            return
        }
        if(!this.form.bannerUrl){
            this.$message.warning('请填写首页链接')
            return
        }


        // 去重
        let addset = new Set(this.newTags)
        let addNew = Array.from(addset)
        this.form.addLabel = addNew
        if(this.activityNumber) {
            // 去重
            let set = new Set(this.delTags)
            let newArr = Array.from(set)
            this.form.delLabel = newArr
        }

        this.form.validityStartTime = this.tradingHour[0]
        this.form.validityEndTime = this.tradingHour[1]
        this.sureLoading = true
        api
            .post(this.activityNumber ? 'J27125' : 'J27124', this.form)
            .then(res =>{
                this.tags = []
                this.newTags = []
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                if(this.$route.query.activityNumber) {
                    this.getDetail(this.$route.query.activityNumber)
                }
                this.$router.push({
                    name: 'applet_activity'
                })
            }).finally(() => {
                this.sureLoading = false
        })

    }


    // 编辑
    getDetail(activityNumber: any) {
        api
            .post('J27126', {activityNumber: this.activityNumber})
            .then(async (res: any) => {
                this.form = res
                this.tradingHour = [res.validityStartTime, res.validityEndTime]
                this.influenceRange = this.form.influenceRange.split(',')
                this.form.popup = res.popup?res.popup.toString() : ''
                this.form.bannerStatus = res.bannerStatus ? res.bannerStatus.toString() : '2'

                if(res.label) {
                    this.tags = showTagRE(res.label).split(',')
                    this.tagsC = [...this.tags]
                }

                this.fileList = {
                    image_url: res.popupImg,
                    image_name: res.popupImg
                }
                this.fileList2 = {
                    image_url: res.bannerImg,
                    image_name: res.bannerImg
                }
            })
    }

    activityNumber: any = ''
    showUpload = true
    activated() {
        this.form = {}
        this.influenceRange = []
        this.tradingHour = []
        this.tags = []
        this.tagsC = []
        this.newTags = []
        this.delTags = []
        this.fileList = {
            image_url: '',
            image_name: ''
        }
        this.fileList2 = {
            image_url: '',
            image_name: ''
        }
        this.activityNumber = this.$route.query.activityNumber
        if(this.activityNumber) {
            this.getDetail(this.activityNumber)
        }
        this.labelTable()
        this.showUpload = false
        this.$nextTick(() => {this.showUpload = true})

    }


}
